import { IconSupport } from 'modules/common/components/_icons/icons'
import { Button } from 'modules/common/components/_ui/Button/Button'
import useSupportModal from 'modules/common/modals/useSupportModal/useSupportModal'

export const SupportButton = () => {
  const { showModal: showSupportModal } = useSupportModal()

  return (
    <Button
      type="button"
      variant="none"
      onClick={showSupportModal}
      icon={<IconSupport />}
    />
  )
}
